<template>
  <div class="flying-button">
    <v-speed-dial v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left" :direction="direction" :open-on-hover="hover" :transition="transition">
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary darken-2" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>
      <!-- <v-btn fab dark small color="green">
        <v-icon>mdi-pencil</v-icon>
      </v-btn> -->
      <slot></slot>
    </v-speed-dial>
  </div>
</template>
<script>
export default {
  data: () => ({
    direction: 'top',
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: 'slide-y-reverse-transition',
  }),

  watch: {
    top(val) {
      this.bottom = !val
    },
    right(val) {
      this.left = !val
    },
    bottom(val) {
      this.top = !val
    },
    left(val) {
      this.right = !val
    },
  },
}
</script>
<style>
/* This is for documentation purposes and will not be needed in your application */
.flying-button .v-speed-dial {
  position: fixed;
}

.flying-button .v-btn--floating {
  position: relative;
}
</style>