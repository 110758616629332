import Vue from "vue";
import Vuex from "vuex";

import modBeritaAcara from "./modules/modBeritaAcara";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    siteIcon: "",
    siteName: "",
    companyName: "PT. Beton Perkasa Wijaksana",
    siteCopyright: "PT. Beton Perkasa Wijaksana",
    loadingOverlay: false,
    helper: {
      listRmCriteria: ['Repair', 'Maintenance', 'Reject', 'Good'],
      listItemCategory: ['FORMWORK GIRDERS', 'WALL FORMWORK', 'COLUMN FORMWORK', 'SLAB FORMWORK', 'SHORING SYSTEMS', 'CLIMBING SYSTEMS', 'ACCESS & WORKING PLATFORM', 'CIVIL WORKS', 'PLYWOOD, SYSTEMS INDEPENDENT ACCESORIES', 'SERVICE', 'CONSUMABLE BPW',],
    },
    get apiToken() {
      return localStorage.getItem('BPW_STORAGE_apiToken');
    },
    set apiToken(value) {
      localStorage.setItem('BPW_STORAGE_apiToken', value);
    },
    get isLoggedIn() {
      if (localStorage.getItem('BPW_STORAGE_isLoggedIn') == 'true') {
        return true;
      } else {
        return false;
      }
    },
    set isLoggedIn(value) {
      localStorage.setItem('BPW_STORAGE_isLoggedIn', value);
    },
    get localData() {
      return localStorage.getItem('BPW_STORAGE_local_data')
    },
    set localData(value) {
      localStorage.setItem('BPW_STORAGE_local_data', value);
    },
    loginInfo: {
      get userId() {
        return localStorage.getItem('BPW_STORAGE_userId');
      },
      set userId(value) {
        localStorage.setItem('BPW_STORAGE_userId', value);
      },
      get username() {
        return localStorage.getItem('BPW_STORAGE_username');
      },
      set username(value) {
        localStorage.setItem('BPW_STORAGE_username', value);
      },
      get firstName() {
        return localStorage.getItem('BPW_STORAGE_firstName');
      },
      set firstName(value) {
        localStorage.setItem('BPW_STORAGE_firstName', value);
      },
      get lastName() {
        return localStorage.getItem('BPW_STORAGE_lastName');
      },
      set lastName(value) {
        localStorage.setItem('BPW_STORAGE_lastName', value);
      },
      get fullName() {
        return localStorage.getItem('BPW_STORAGE_firstName') + ' ' + localStorage.getItem('BPW_STORAGE_lastName');
      },
      get email() {
        return localStorage.getItem('BPW_STORAGE_email');
      },
      set email(value) {
        localStorage.setItem('BPW_STORAGE_email', value);
      },
      get profileImage() {
        return localStorage.getItem('BPW_STORAGE_profileImage');
      },
      set profileImage(value) {
        localStorage.setItem('BPW_STORAGE_profileImage', value);
      },
      get userType() {
        return localStorage.getItem('BPW_STORAGE_userType');
      },
      set userType(value) {
        localStorage.setItem('BPW_STORAGE_userType', value);
      },
      get userLevel() {
        return localStorage.getItem('BPW_STORAGE_userLevel');
      },
      set userLevel(value) {
        localStorage.setItem('BPW_STORAGE_userLevel', value);
      },
    },
    get userMenu() {
      return localStorage.getItem('BPW_STORAGE_userMenu');
    },
    set userMenu(value) {
      localStorage.setItem('BPW_STORAGE_userMenu', value);
    },
    get userModule() {
      return localStorage.getItem('BPW_STORAGE_userModule');
    },
    set userModule(value) {
      localStorage.setItem('BPW_STORAGE_userModule', value);
    },
    snackBar: {
      show: false,
      message: ""
    },
    runRenderMenu: false,
    master: {
      area: [
        {
          "area_code": "AA",
          "area_name": "P40",
          "regional_code": "R2",
          "regional_name": "REGIONAL 2"
        },
        {
          "area_code": "AM",
          "area_name": "CEGER",
          "regional_code": "R1",
          "regional_name": "REGIONAL 1"
        },
        {
          "area_code": "BB",
          "area_name": "PANDAAN",
          "regional_code": "R3",
          "regional_name": "REGIONAL 3"
        },
        {
          "area_code": "BD",
          "area_name": "SEMARANG",
          "regional_code": "R3",
          "regional_name": "REGIONAL 3"
        },
        {
          "area_code": "BJ",
          "area_name": "MAKASSAR",
          "regional_code": "R5",
          "regional_name": "REGIONAL 5"
        },
        {
          "area_code": "BK",
          "area_name": "BALIKPAPAN",
          "regional_code": "R5",
          "regional_name": "REGIONAL 5"
        },
        {
          "area_code": "BQ",
          "area_name": "BALI",
          "regional_code": "R3",
          "regional_name": "REGIONAL 3"
        },
        {
          "area_code": "BU",
          "area_name": "BANJARMASIN",
          "regional_code": "R5",
          "regional_name": "REGIONAL 5"
        },
        {
          "area_code": "CN",
          "area_name": "TANGERANG",
          "regional_code": "R2",
          "regional_name": "REGIONAL 2"
        },
        {
          "area_code": "CP",
          "area_name": "BANDUNG",
          "regional_code": "R1",
          "regional_name": "REGIONAL 1"
        },
        {
          "area_code": "FF",
          "area_name": "PALEMBANG",
          "regional_code": "R4",
          "regional_name": "REGIONAL 4"
        },
        {
          "area_code": "FG",
          "area_name": "PEKANBARU",
          "regional_code": "R4",
          "regional_name": "REGIONAL 4"
        },
        {
          "area_code": "FI",
          "area_name": "MEDAN",
          "regional_code": "R4",
          "regional_name": "REGIONAL 4"
        },
        {
          "area_code": "FT",
          "area_name": "BATAM",
          "regional_code": "R4",
          "regional_name": "REGIONAL 4"
        },
        {
          "area_code": "HO",
          "area_name": "CIKUPA",
          "regional_code": "HO",
          "regional_name": "CIKUPA"
        },
      ],
      regional: [
        {
          "regional_code": "HO",
          "regional_name": "CIKUPA"
        },
        {
          "regional_code": "R1",
          "regional_name": "REGIONAL 1"
        },
        {
          "regional_code": "R2",
          "regional_name": "REGIONAL 2"
        },
        {
          "regional_code": "R12",
          "regional_name": "REGIONAL 1 & 2"
        },
        {
          "regional_code": "R3",
          "regional_name": "REGIONAL 3"
        },
        {
          "regional_code": "R4",
          "regional_name": "REGIONAL 4"
        },
        {
          "regional_code": "R5",
          "regional_name": "REGIONAL 5"
        }
      ],
    },
  },
  mutations: {
  },
  actions: {},
  modules: {
    modBeritaAcara
  },
});


