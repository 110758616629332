<template>
  <v-app>
    <v-main class="pa-0">
      <v-img :src="require('@/assets/login-bg.jpg')" :style="(!isMobile) ? 'height:100vh':''" class="fill-height">
        <slot></slot>
      </v-img>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      status: null,
    };
  },
  computed: {},
};
</script>