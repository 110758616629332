<template>
  <div style="display: inline">
    <a href="javascript:;" @click="showDialog()">
      <slot></slot>
    </a>
    <v-dialog v-model="dialog">
      <v-card>
        <v-img :src="src" max-height="600" class="blue-grey darken-3" contain>
          <v-toolbar color="transparent" elevation="0">
            <v-toolbar-title style="color: aliceblue;">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="goToExternalURL(src)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn icon @click="dialog = false" dark>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["src", "title"],
  data: () => ({
    dialog: null,
  }),

  methods: {
    showDialog() {
      this.dialog = true
    }
  }
};
</script>
