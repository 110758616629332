<template>
  <v-menu offset-y right bottom left>
    <template v-slot:activator="{ attrs, on }">
      <v-avatar v-bind="attrs" v-on="on" size="36" class="mx-1">
        <v-img :src="resourceUrl($store.state.loginInfo.profileImage)"></v-img>
      </v-avatar>
    </template>

    <v-card min-width="250">
      <v-card-text class="px-0 py-0">
        <v-card-text class="text-center">
          <div class="mx-auto d-block mb-2">
            <v-avatar style="cursor:pointer" size="72">
              <v-img :src="imageUrl"></v-img>
            </v-avatar>
          </div>
          <span class="d-block mx-auto">{{ username }}</span>
          <span class="d-block mx-auto">{{ fullName }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="redirect('Account')" color="primary">Account</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="signOut()" color="primary">Logout</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

  </v-menu>
</template>

<script>
export default {
  computed: {
    imageUrl(){
      return (this.$store.state.loginInfo.profileImage) ? this.$store.state.loginInfo.profileImage : '';
    },
    username() {
      return this.$store.state.loginInfo.username;
    },
    fullName() {
      return this.$store.state.loginInfo.fullName;
    },
  }
}
</script>