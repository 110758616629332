<template>
  <div style="display: inline-block;">
    <div href="javascript:;" @click="showDialog()">
      <slot></slot>
    </div>
    <v-dialog v-model="dialog" class="dialogdetail" max-width="1200">
      <v-card :loading="loading">
        <v-card-title class="text-h5 grey lighten-2">
          Document Log
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ms-2" title="Refresh" v-bind="attrs" v-on="on" @click="refreshData()">mdi-refresh</v-icon>
            </template>
            <span>Refresh</span>
          </v-tooltip>

          <v-btn color="light" @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-4" style="max-height: 550px;overflow-y: auto;">
          <v-row>
            <v-col cols="12">
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-text-field dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-sheet elevation="1" style="min-height: 250px;">
          <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
            <template v-slot:[`item.log_datetime`]="{ item }">
              <a href="javascript:;" @click="showDetail(item)">{{ item.log_datetime }}</a>
            </template>
          </v-data-table>
        </v-sheet>

        <v-divider></v-divider>

        <v-card-actions>
          <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
          <v-spacer></v-spacer>
          <v-btn color="light" @click="dialog = false" small> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailDialog" max-width="1200" scrollable>
      <v-card>
        <v-toolbar dense elevation="0" outlined>
          <v-toolbar-title>{{ selected_item.log_datetime }} - {{ selected_item.username }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text icon @click="showBefore()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn text icon @click="showAfter()">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <v-btn text icon @click="detailDialog = !detailDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pe-1 py-2">
          <div class="d-block">

            <v-sheet class="py-2 px-2" elevation="0" outlined>
              <dl class="d-flex">
                <dt class="pe-2">Date Time:</dt>
                <dd>{{ selected_item.log_datetime }}</dd>
              </dl>
              <dl class="d-flex">
                <dt class="pe-2">Username:</dt>
                <dd>{{ selected_item.username }}</dd>
              </dl>
              <dl class="d-flex">
                <dt class="pe-2">Activity:</dt>
                <dd>{{ selected_item.log_activity }}</dd>
              </dl>
              <dl class="d-flex">
                <dt class="pe-2">Description:</dt>
                <dd>{{ selected_item.log_description }}</dd>
              </dl>
            </v-sheet>
            <ul style="list-style-type: none;border-left: 1px solid #ddd;">
              <li v-for="(item, i) in selected_item.log_data" :key="i" style="padding-top: 0px; padding-bottom: 0px;">
                <div v-if="Array.isArray(item)" style="border-bottom: 1px solid #ddd;">
                  <ul style="list-style-type: none;border-left: 1px solid #ddd;">
                    <li v-for="(item2, ii) in item" :key="ii">
                      <ul style="list-style-type: none;border-left: 1px solid #ddd;">
                        <li v-for="(item3, iii) in Object.keys(item2).map((key) => [key, item2[key]])" :key="iii"><b>{{ item3[0] }}</b> : <span class="ml-1">{{ item3[1] }}</span></li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div v-else style="border-bottom: 1px solid #ddd;">
                  <ul style="list-style-type: none;border-left: 1px solid #ddd;">
                    <li v-for="(item2, ii) in Object.keys(item).map((key) => [key, item[key]])" :key="ii"><b>{{ item2[0] }}</b> : <span class="ml-1">{{ item2[1] }}</span></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="detailDialog = !detailDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'app-log-data-dialog',
  props: ['id', 'module_name', 'callback'],
  data() {
    return {
      dialog: null,
      loading: null,
      search: "",
      datatable_options: {},
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "Date Time", value: "log_datetime" },
          { text: "Username", value: "username" },
          { text: "Activity", value: "log_activity" },
          { text: "Description", value: "log_description" },
        ],
        data: [],
      },
      selected_item: {
        user_id: 0,
        username: '',
        log_datetime: '',
        log_activity: '',
        log_data: {},
      },
      detailDialog: false,
    };
  },
  watch: {
    dialog(n, o) {
      if ((o == true) && (n == false)) {
        if (typeof this.callback === 'function') {
          this.callback()
        }
      }

      if ((o == false) && (n == true)) {
        this.getData();
      }
    },
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    refreshData() {
      this.getData();
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", parseInt(page));
      formData.append("limit", parseInt(itemsPerPage));

      formData.append("module_name", this.module_name);
      formData.append("id", parseInt(this.id));

      this.$axios
        .post("log/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    showDetail(item) {
      this.selected_item.user_id = item.user_id;
      this.selected_item.username = item.username;
      this.selected_item.log_datetime = item.log_datetime;
      this.selected_item.log_activity = item.log_activity;
      this.selected_item.log_data = JSON.parse(item.log_data);

      this.detailDialog = true;
    },
    showBefore() {
      if (typeof this.selected_item.user_id == 'undefined') {
        return;
      }

      console.log(this.datatable.data.length);

      var current_index = 0;
      for (var i = 0; i < this.datatable.data.length; i++) {
        if (this.datatable.data[i].log_datetime == this.selected_item.log_datetime) {
          if (i > 0) {
            current_index = i;
            console.log(current_index);
            break;
          }
        }
      }

      var index_before = (current_index - 1);

      if (index_before < 0) return;

      if (this.datatable.data[index_before] != 'undefined') {
        this.selected_item.user_id = this.datatable.data[index_before].user_id;
        this.selected_item.username = this.datatable.data[index_before].username;
        this.selected_item.log_datetime = this.datatable.data[index_before].log_datetime;
        this.selected_item.log_activity = this.datatable.data[index_before].log_activity;
        this.selected_item.log_data = JSON.parse(this.datatable.data[index_before].log_data);
      }

    },
    showAfter() {
      if (typeof this.selected_item.user_id == 'undefined') {
        return;
      }

      var current_index = 0;
      for (var i = 0; i < this.datatable.data.length; i++) {
        if (this.datatable.data[i].log_datetime == this.selected_item.log_datetime) {
          if (i < this.datatable.data.length) {
            current_index = i;

            console.log(current_index);
            break;
          }
        }
      }

      if (index_after > (this.datatable.data.length - 1)) return;

      var index_after = (current_index + 1);
      if (this.datatable.data[index_after] != 'undefined') {
        this.selected_item.user_id = this.datatable.data[index_after].user_id;
        this.selected_item.username = this.datatable.data[index_after].username;
        this.selected_item.log_datetime = this.datatable.data[index_after].log_datetime;
        this.selected_item.log_activity = this.datatable.data[index_after].log_activity;
        this.selected_item.log_data = JSON.parse(this.datatable.data[index_after].log_data);
      }

    }
  },
};
</script>
