<template>
  <v-list dense>
    <div v-for="(item, i) in items" :key="i">
      <v-list-item v-if="item.items.length == 0" link :to="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>

      <v-list-group v-if="item.items.length >= 1" :key="item.title" :prepend-icon="item.icon" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-if="item.items.length >= 1">
          <v-list-item v-for="child in item.items" :key="child.title" link :to="child.to">
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-group>
    </div>
  </v-list>
</template>
<script>
export default {
  name: "NavbarScm",
  components: {},
  data() {
    return {
      drawer: null,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          to: { name: "Dashboard" },
          items: [],
        },
        {
          title: "Campaign",
          icon: "mdi-office-building",
          to: { name: "Campaign" },
          items: [],
        },
        {
          title: "R M P",
          icon: "mdi-truck",
          to: { name: "Rmp" },
          items: [
            // {
            //   title: "List",
            //   icon: "mdi-list",
            //   to: { name: "Rmp" },
            //   items: [],
            // },
          ],
        },
        {
          title: "About",
          icon: "mdi-help-box",
          to: { name: "About" },
          items: [],
        },
      ],
      right: null,
    };
  },
  computed: {},
  methods: {},
};
</script>