<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="inputVal" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field dense hide-details flat outlined class="mb-3" v-model="inputVal" label="Picker in dialog" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
    </template>
    <v-date-picker v-model="inputVal" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(inputVal)">
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  props: ['value'],
  data: () => ({
    modal: false,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>