// eslint-disable-next-line no-unused-vars
import moment from "moment";

export default {
  data: () => ({
    localStoragePrefix: "BPW_STORAGE_APP_",
  }),
  methods: {
    setLocalStorage(name, data, exp=1) {
      var _data = data;
      if (Array.isArray(data)) {
        _data = JSON.stringify(data);
      }
      localStorage.setItem(this.localStoragePrefix + name, _data);

      
      var _exp_date = new Date(new Date().getTime()+(exp*1000));
      
      localStorage.setItem(this.localStoragePrefix + name + '_exp', _exp_date);
    },

    setLS(name, data, exp){
      this.setLocalStorage(name, data, exp)
    },

    getLocalStorage(name) {
      var exp = localStorage.getItem(this.localStoragePrefix + name + '_exp');
      var _expDate = new Date(exp);
      var _currentDate = new Date();
      if(_currentDate > _expDate){
        this.removeLocalStorage(name);
        return null;
      }

      var data = localStorage.getItem(this.localStoragePrefix + name);
      // Is Json?
      var isJson = function (data) {
        try {
          JSON.parse(data);
          return true;
        } catch (err) {
          return false;
        }
      };

      if (isJson(data)) {
        return JSON.parse(data);
      }

      return data;
    },

    getLS(name){
      return this.getLocalStorage(name)
    },

    removeLocalStorage(name) {
      localStorage.removeItem(this.localStoragePrefix + name);
      localStorage.removeItem(this.localStoragePrefix + name + '_exp');
    },

    removeLS(name){
      return this.removeLocalStorage(name)
    },
  },
};
