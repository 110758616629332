const modBeritaAcara = {
  namespaced: true,
  state: () => ({
    count: 0,
    loading: false,
    data: [],
  }),
  mutations: {
    increment(state) {
      // `state` is the local module state
      state.count++;
    },

    setData(state, payload){
      state.data = payload
    }
  },

  getters: {
    doubleCount(state) {
      return state.count * 2;
    },
  },
};

export default modBeritaAcara;
