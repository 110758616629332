<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 8 : 2" :class="{ 'on-hover': hover }" v-bind="$attrs">
      <v-toolbar elevation="0" dense>
        <slot v-if="hideTitle" name="title"></slot>
        <v-toolbar-title v-if="!hideTitle" @click="minimize=!minimize" style="cursor:pointer;">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="action"></slot>
        <v-btn small color="primary" icon @click="minimize=!minimize">
          <v-icon v-if="minimize">mdi-chevron-down</v-icon>
          <v-icon v-if="!minimize">mdi-chevron-up</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-show="!minimize" :class="bodyClass">
        <v-expand-transition>
          <v-sheet>
            <slot></slot>
          </v-sheet>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </v-hover>
</template>


<script>
export default {
  inheritAttrs: false,
  props: {
    title: String,
    isMinimize: {
      type: Boolean,
      default: null,
    },
    noPadding: {
      type: Boolean,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      minimize: false,
      bodyClass: "",
    };
  },
  mounted() {
    if (this.isMinimize === true) {
      this.minimize = true;
    }

    if (this.noPadding === true) {
      this.bodyClass = this.bodyClass + ' pa-0 ';
    }
  },
};
</script>