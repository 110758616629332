<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <v-tooltip bottom v-model="showTooltip">
      <template v-slot:activator="{ on }">
        <div @mouseover="showTooltip = true" @mouseleave="showTooltip = false" v-on="on">
          <slot name="activator" :on="onShow"></slot>
        </div>
      </template>
      <span>{{ title }}</span>
    </v-tooltip>
    <v-navigation-drawer v-model="drawer" fixed bottom temporary right :width="width" :class="(hideToolbar) ? '' : 'pt-12'">
      <v-toolbar elevation="0" dense absolute width="100%" v-if="!hideToolbar">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon text small @click.stop="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="!hideToolbar"></v-divider>
      <div>
        <div class="text-center mx-auto my-4" v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <slot></slot>
      </div>
      <v-toolbar elevation="0" dense absolute width="100%" bottom min-height="0px" v-if="showBottomNav">
        <div ref="refBottomNav">
          <slot name="action"></slot>
        </div>
      </v-toolbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 350
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideToolbar: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    attr: {},
    showBottomNav: true,
    showTooltip: false,
  }),

  computed: {

  },

  methods: {
    onShow() {
      this.drawer = !this.drawer;

      this.bottomNavToggle()
    },
    bottomNavToggle() {
      const childElement = this.$refs.refBottomNav;
      if (typeof childElement.innerHTML !== 'undefined') {
        this.showBottomNav = true;
        return;
      }

      // Check if the child element is empty
      if (childElement.innerHTML.trim() === '') {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = true;
      }
      // return false;
    }
  },

  watch: {
    group() {
      this.drawer = false
    },
  },

  mounted() {

  }
}
</script>