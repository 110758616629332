/* eslint-disable no-unused-vars */
import AxiosStorage from "axios-storage";

export default {
  data: () => ({
    loginInfo: {
      get username() {
        return localStorage.getItem("username");
      },
      set username(value) {
        localStorage.setItem("BPW_STORAGE_username", value);
      },
      get firstName() {
        return localStorage.getItem("firstName");
      },
      set firstName(value) {
        localStorage.setItem("BPW_STORAGE_firstName", value);
      },
      get lastName() {
        return localStorage.getItem("lastName");
      },
      set lastName(value) {
        localStorage.setItem("BPW_STORAGE_lastName", value);
      },
      get email() {
        return localStorage.getItem("email");
      },
      set email(value) {
        localStorage.setItem("BPW_STORAGE_email", value);
      },
      get profileImage() {
        return localStorage.getItem("profileImage");
      },
      set profileImage(value) {
        localStorage.setItem("BPW_STORAGE_profileImage", value);
      },
    },
  }),
  methods: {
    saveApiToken(token) {
      localStorage.setItem("BPW_STORAGE_apiToken", token);
      return localStorage.getItem("apiToken");
    },
    deleteApiToken() {
      localStorage.setItem("BPW_STORAGE_apiToken", "");
    },
    getAccountInfo() {
      var api_token = localStorage.getItem("BPW_STORAGE_apiToken");
      if (api_token == "undefined") {
        this.$store.state.isLoggedIn = false;
        return null;
      } else {
        this.apiGet("account/info")
          .then((res) => {
            if (res.data.status == "success") {
              localStorage.setItem(
                "BPW_STORAGE_username",
                res.data.data.username
              );
              localStorage.setItem(
                "BPW_STORAGE_firstName",
                res.data.data.first_name
              );
              localStorage.setItem(
                "BPW_STORAGE_lastName",
                res.data.data.last_name
              );
              localStorage.setItem(
                "BPW_STORAGE_profileImage",
                res.data.data.user_img
              );

              console.log(res.data.data);

              return res.data.data;
            } else {
              localStorage.setItem("BPW_STORAGE_username", "");
              localStorage.setItem("BPW_STORAGE_firstName", "");
              localStorage.setItem("BPW_STORAGE_lastName", "");
              localStorage.setItem("BPW_STORAGE_profileImage", "");

              this.$store.state.isLoggedIn = false;
              return null;
            }
          })
          .catch((err) => {
            console.log(err);
            localStorage.setItem("BPW_STORAGE_username", "");
            localStorage.setItem("BPW_STORAGE_firstName", "");
            localStorage.setItem("BPW_STORAGE_lastName", "");
            localStorage.setItem("BPW_STORAGE_profileImage", "");

            this.$store.state.isLoggedIn = false;
            return this.$store.state.loginInfo.null;
          });
      }
    },
    clearStorage() {
      localStorage.removeItem("BPW_STORAGE_apiToken");
      localStorage.removeItem("BPW_STORAGE_email");
      localStorage.removeItem("BPW_STORAGE_firstName");
      localStorage.removeItem("BPW_STORAGE_isLoggedIn");
      localStorage.removeItem("BPW_STORAGE_lastName");
      localStorage.removeItem("BPW_STORAGE_email");
      localStorage.removeItem("BPW_STORAGE_profileImage");
      localStorage.removeItem("BPW_STORAGE_userLevel");
      localStorage.removeItem("BPW_STORAGE_userModule");
      localStorage.removeItem("BPW_STORAGE_username");
      localStorage.removeItem("BPW_STORAGE_userType");
      localStorage.removeItem("BPW_STORAGE_menuItems");
    },
    requiredLogin() {
      if (this.isLoggedIn()) {
        return true;
      } else {
        window.location.replace("/login");
      }
    },
    signOut() {
      let options = {
        html: true, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
        reverse: true, // switch the button positions (left to right, and vise versa)
        okText: "Continue",
        cancelText: "Close",
        animation: "zoom", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        verification: "continue", // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: true, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: "", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$dialog
        .confirm(
          '<h1 style="margin-bottom: 24px;">Logout</h1>' +
            "Please confirm to continue",
          options
        )
        // eslint-disable-next-line no-unused-vars
        .then(() => {
          // Remove All Cache
          let oCache = AxiosStorage.getCache("sessionStorage");
          oCache.removeAll();

          // Remove All Storage
          this.clearStorage();

          // Redirect to Login Page
          this.$router.push("/login");
        })
        .catch(function (error) {
          console.log(error);
          null;
        });
    },
    modulePermission(module, perm_name, redirect = false) {
      if (this.$store.state.loginInfo.userLevel == "sa") return true;

      var perms = JSON.parse(this.$store.state.userModule);
      var ret = false;
      if (typeof perms !== "undefined" && perms !== null) {
        if (perms.length > 0) {
          perms.forEach((item, i) => {
            if (item.module === module && item.perm_name === perm_name)
              ret = true;
          });
        }
      }

      if (ret == false && redirect == true)
        return this.$router.push({ name: "404" });
      return ret;
    },
  },
};
