<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-img
        :src="selectedImage"
        max-height="600"
        class="blue-grey darken-3"
        contain
      >
        <v-toolbar color="transparent" elevation="0">
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: null,
    selectedImage: '',
  }),

  methods: {
    showDialog(selectedImage='') {
      this.dialog = true
      this.selectedImage = selectedImage
    }
  }
};
</script>
