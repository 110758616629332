import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      // light: {
      //   primary: '#ed1c24', 
      //   secondary: '#ffdd00', 
      //   // accent: '#943c3a', 
      //   // danger: '#910A00', 
      //   // warning: '#ffdd00', 
      //   'beton-red': '#ed1c24', 
      //   'beton-yellow': '#ffdd00', 
      //   'beton-black': '#000000', 
      //   'beton-grey': '#ebeced', 
      //   'beton-dark-brown': '#943c3a', 
      // },
      light: {
        primary: '#ed1c24',
        // secondary: '#ffdd00',
        // accent: '#943c3a', 
        // danger: '#910A00', 
        // warning: '#ffdd00', 
        'beton-red': '#ed1c24',
        'beton-yellow': '#ffdd00',
        'beton-black': '#000000',
        'beton-grey': '#ebeced',
        'beton-dark-grey': '#303030',
        'beton-dark-brown': '#943c3a',
      },
    },
  },
});
