<template>
  <div>
    <v-btn v-bind="$attrs" v-on="$listeners" @mouseenter="show = true" @mouseleave="show = false" :small="small" :color="color" :class="internalClass" :icon="isIcon" :elevation="elevation" :text="text">
      <v-tooltip bottom v-model="tooltip1" show-arrows>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex align-center">
            <v-icon>{{ mdiIcon }}</v-icon> <span v-if="showLabel">{{ label }}</span>
          </div>
        </template>
        <template>
          <span>{{ title }}</span>
        </template>
      </v-tooltip>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'app-button',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "grey darken-3"
    },
    small: {
      type: Boolean,
      default: true
    },
    mdiIcon: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    elevation: {
      type: String,
      default: "0"
    },
    text: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      tooltip1: false,
      btnClass: "px-1",
    };
  },
  computed: {
    isIcon() {
      return (this.mdiIcon != '' && this.label.length == 0) ? true : false;
    },
    showLabel() {
      return (this.label.length >= 1) ? true : false;
    },
    internalClass() {
      return this.btnClass + ' ' + this.$attrs.class;
    }
  },
  watch: {
    title(v){
      if(v.length >= 1){
        this.tooltip1 = false
      }
    }
  }
};
</script>