<template>
  <div>
    <v-btn small color="light" v-bind="$attrs" v-on="$listeners" icon @mouseenter="show = true" @mouseleave="show = false">
      <v-tooltip bottom v-model="show">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-close</v-icon>
        </template>
        <template>
          <span>Close</span>
        </template>
      </v-tooltip>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'app-button-close',
  inheritAttrs: false,
  data() {
    return {
      show: false,
    };
  },
};
</script>