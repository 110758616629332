<template>
  <div>
    <LayoutLogin v-if="resolveLayout == 'layout-login'">
      <router-view></router-view>
      <notifications group="default" style="bottom: 15px" :duration="3000" :position="position"></notifications>
      <v-overlay :value="overlay" z-index="999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </LayoutLogin>
    <LayoutBlank v-if="resolveLayout == 'layout-blank'">
      <router-view></router-view>
      <notifications group="default" style="bottom: 15px" :duration="3000" :position="position"></notifications>
      <v-overlay :value="overlay" z-index="999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </LayoutBlank>
    <LayoutDefault v-if="resolveLayout == 'layout-default'" :drawer="drawer" ref="refComp">
      <router-view @drawerControl="drawerControl"></router-view>
      <notifications group="default" style="bottom: 15px" :duration="3000" :position="position"></notifications>
      <v-overlay :value="overlay" z-index="999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </LayoutDefault>

    <!-- <v-snackbar v-model="snackbar" :vertical="vertical">
      {{ $store.state.snackBar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="$store.state.snackBar.show = false">Close</v-btn>
      </template>
    </v-snackbar> -->
  </div>
</template>

<script>
import LayoutBlank from "@/layouts/Blank.vue";
import LayoutDefault from "@/layouts/Default.vue";
import LayoutLogin from "@/layouts/Login.vue";

export default {
  components: {
    LayoutBlank,
    LayoutDefault,
    LayoutLogin,
  },

  data() {
    return {
      drawer: null,
    };
  },

  computed: {
    resolveLayout() {
      // Handles initial route
      if (this.$route.name === null) return null;

      if (this.$route.meta.layout === "blank") return "layout-blank";
      if (this.$route.meta.layout === "login") return "layout-login";

      return "layout-default";
    },
    overlay() {
      return this.$store.state.loadingOverlay;
    },
    snackbar() {
      return this.$store.state.snackBar.show;
    },
    position() {
      var pos = "bottom right";
      if (this.isMobile) {
        pos = "bottom center";
      }
      return pos;
    },
  },

  methods: {
    drawerControl() {
      this.$refs.refComp.drawerControl();
    },
  },

  beforeCreate() {},

  created() {
    if (this.isLoggedIn()) this.getModule();
  },

  mounted() {},
};
</script>
