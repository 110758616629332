<template>
  <v-sheet :class="'d-flex' + ((isMobileView) ? ' flex-column ': '')" color="transparent" flat tile class="mb-2">
    <template v-if="isMobileView">
      <v-sheet class="mr-auto transparent " outlined tile>
        <h1 class="text-h4 mb-2">
          {{ title }}
          <slot v-if="title==''"></slot>
        </h1>
      </v-sheet>

      <v-sheet class="transparent d-flex align-center" outlined tile>
        <slot name="action"></slot>
      </v-sheet>
    </template>
    <template v-else>
      <v-sheet class="mr-auto transparent" outlined tile>
        <h1 class="text-h4 mb-2">
          {{ title }}
          <slot v-if="title==''"></slot>
        </h1>
      </v-sheet>

      <v-sheet class="transparent d-flex align-center" outlined tile>
        <slot name="action"></slot>
      </v-sheet>
    </template>
  </v-sheet>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data: () => ({ isMobileView: false }),
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobileView = window.innerWidth < 600;
    },
  },
};
</script>