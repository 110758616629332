<template>
  <v-dialog max-width="600" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text small icon v-bind="attrs" v-on="on">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex" style="line-height: normal;">
        <v-avatar>
          <v-img v-if="(typeof data.user_img !== 'undefined')" :src="resourceUrl(data.user_img)"></v-img>
        </v-avatar>
        <div class="px-2">
          <h6 style="font-size: 14px;" v-if="(typeof data.username !== 'undefined')">{{ data.username }}</h6>
          <small class="d-block text--disabled" style="font-size: x-small;" v-if="(typeof data.employee_position !== 'undefined')">{{ data.employee_position }}</small>
          <small class="d-block text--disabled" style="font-size: x-small;" v-if="(typeof data.comment_datetime !== 'undefined')">{{ data.comment_datetime }}</small>
        </div>
      </v-card-title>

      <v-card-text v-if="(typeof data.comment !== 'undefined')">
        <div class="cpre">
          <pre class="ipre" style="white-space: pre-wrap;">{{ data.comment }}</pre>
        </div>
        <!-- <v-textarea style="border: none !important;width: 100%; max-height: 300px" rows="" :value="data.comment" readonly class="mb-1" hide-details></v-textarea> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      dialog: null
    }
  }
}
</script>

<style scoped>
div.cpre {
  max-height: 260px;
  overflow-y: auto;
}

.ipre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
</style>