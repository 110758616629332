<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <!-- <v-tooltip bottom v-model="showTooltip">
      <template v-slot:activator="{ on }">
        <div @mouseover="showTooltip = true" @mouseleave="showTooltip = false" v-on="on">
          <slot name="activator" :on="onShow"></slot>
        </div>
      </template>
      <span>{{ title }}</span>
    </v-tooltip> -->
    <slot name="activator" :on="onShow"></slot>
    <v-navigation-drawer v-bind="$attrs" v-on="$listeners" v-model="internalValue" fixed bottom temporary right :width="width" :class="(hideToolbar) ? '' : 'pt-12'" ref="refDrawer">
      <v-toolbar elevation="0" dense absolute width="100%" v-if="!hideToolbar">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon text small @click.stop="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="!hideToolbar"></v-divider>
      <v-card elevation="0" :outlined="false" :max-height="contentHeight" style="overflow-y: auto;">
        <div class="text-center mx-auto my-4" v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <slot></slot>
      </v-card>
      <v-toolbar elevation="0" dense absolute width="100%" bottom min-height="0px" v-if="!hideBottomToolbar">
        <div ref="refBottomNav">
          <slot name="action"></slot>
        </div>
      </v-toolbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "app-right-bar",
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 350
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideToolbar: {
      type: Boolean,
      default: false
    },
    hideBottomToolbar: {
      type: Boolean,
      default: false
    },
    callback: null,
  },
  data: () => ({
    internalValue: false,
    group: null,
    attr: {},
    showBottomNav: true,
    showTooltip: false,
    contentHeight: 300,
  }),

  computed: {
  },

  methods: {
    onShow() {
      this.internalValue = !this.internalValue;

      // this.bottomNavToggle()
      // const viewportHeight = this.$refs.refDrawer.$el.clientHeight;
      const viewportHeight = document.documentElement.clientHeight;
      this.contentHeight = (viewportHeight);
    },
    bottomNavToggle() {
      const childElement = this.$refs.refBottomNav;
      if (typeof childElement.innerHTML !== 'undefined') {
        this.showBottomNav = true;
        return;
      }

      // Check if the child element is empty
      if (childElement.innerHTML.trim() === '') {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = true;
      }
      // return false;
    },
  },

  watch: {
    group() {
      this.internalValue = false
    },
    internalValue(val) {
      this.$emit('input', val);

      if (val == false) {
        if (typeof this.callback == 'function')
          this.callback();
      }

    },
  },

  mounted() {

  }
}
</script>