<template>
  <v-app>
    <v-main class="my-0 mx-0 py-0 px-0">
      <slot></slot>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "Blank",
  components: {},
  data() {
    return {
      status: null,
    };
  },
  computed: {
  },
};
</script>