<template>
  <div>
    <v-text-field v-bind="$attrs" v-on="$listeners" :value="internalValue" @click="dialog = true" readonly></v-text-field>
    <v-dialog v-model="dialog" persistent max-width="580">
      <v-card>
        <div class="d-flex">
          <v-date-picker v-model="val_date" @change="onDateChange" scrollable class="rounded-0"></v-date-picker>
          <v-time-picker full-width v-model="val_time" format="24hr" @change="onTimeChange" class="rounded-0 xtimepicker"></v-time-picker>
        </div>
        <v-card-actions>
          <v-btn text @click="saveAndClose">OK</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'app-date-time',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.value,
      dialog: false,
      val_date: null,
      val_time: null,
      showDatePicker: true,
    };
  },
  computed: {
    formattedValue() {
      if (this.val_date && this.val_time) {
        return `${this.val_date} ${this.val_time}`;
      }
      return this.val_date || this.val_time || '';
    },
  },
  watch: {
    value(val) {
      if (val && val != '0000-00-00 00:00:00') {
        this.internalValue = val;
        this.val_date = moment(val).format("YYYY-MM-DD");
        this.val_time = moment(val).format("HH:mm");
      } else {
        this.internalValue = new Date();
        this.val_date = moment(new Date()).format("YYYY-MM-DD");
        this.val_time = moment(new Date()).format("HH:mm");
      }
      
    },
    internalValue(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    togglePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    onDateChange(val) {
      this.val_date = val;
    },
    onTimeChange(val) {
      this.val_time = val;
    },
    saveAndClose() {
      this.dialog = false;
      this.$emit('input', this.formattedValue);
    },
  },
};
</script>

<style>
.xtimepicker .v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 56px !important;
  font-size: 56px !important;
}
</style>
