<template>
  <v-app-bar app color="beton-grey" src="@/assets/topbar-bg.jpg" dense>
    <v-btn icon @click="goBack()" v-show="!hideBack">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <slot name="action"></slot>

    <v-menu offset-y v-if="options" close-on-click :rounded="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fab text dark>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <slot name="options"></slot>

    </v-menu>
  </v-app-bar>
</template>
<script>
export default {
  props: {
    hideBack: {
      type: Boolean,
      Default: false,
    },
    title: {
      type: String,
      Default: false,
    },
    options: {
      type: Boolean,
      Default: false,
    },
  },
};
</script>

<style>
.v-main {
  padding-top: 48px !important;
}
</style>