<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <a v-on="on" v-bind="attrs" href="#">
        <slot></slot>
      </a>
    </template>

    <v-card v-if="(typeof dataDetail.user !== 'undefined')">
      <v-img :src="resourceUrl(dataDetail.user.user_img)" height="200" contain class="grey darken-1"></v-img>
      <v-card-title>{{ dataDetail.user.username }}</v-card-title>
      <v-card-subtitle>
        {{ dataDetail.user.first_name + " " + dataDetail.user.last_name }}
      </v-card-subtitle>


      <v-card-actions>
        <v-btn color="orange lighten-2" text @click="showDetail = !showDetail"> View Detail </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDetail = !showDetail">
          <v-icon>{{ showDetail ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="showDetail">
          <v-divider></v-divider>
          <div style="max-height: 275px; overflow-y: auto">
            <v-card-text>
              <v-list-item>
                <v-list-item-title>Username</v-list-item-title>
                <v-list-item-subtitle class="text-right text-wrap">{{ dataDetail.user.username }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>First Name</v-list-item-title>
                <v-list-item-subtitle class="text-right text-wrap">{{ dataDetail.user.first_name }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Last Name</v-list-item-title>
                <v-list-item-subtitle class="text-right text-wrap">{{ dataDetail.user.last_name }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle class="text-right text-wrap">{{ dataDetail.user.email }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Position</v-list-item-title>
                <v-list-item-subtitle class="text-right text-wrap">{{ dataDetail.user.employee_position }}</v-list-item-subtitle>
              </v-list-item>
            </v-card-text>
          </div>
        </div>
      </v-expand-transition>

      <v-card-actions>
        <slot name="action"></slot>
        <v-spacer></v-spacer>
        <v-btn @click.stop="dialog = !dialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'app-user-info',

  props: {
    username: {
      default: "",
      type: String,
    }
  },

  data() {
    return {
      dialog: false,

      dataDetail: {},
      showDetail: false,
    };
  },

  watch: {
    dialog(n) {
      if (n == true) {
        this.getDetail();
      }
    }
  },

  methods: {

    show() {
      this.dialog = true
    },
    hide() {
      this.dialog = false
    },

    getDetail() {
      this.showLoadingOverlay(true);
      var params = {
        username: this.username,
      };
      this.$axios
        .get("public/user-info", {
          params: params,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.dataDetail = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
}
</script>