<template>
  <v-app>
    <!-- <v-system-bar color="beton-yellow" app></v-system-bar> -->
    <v-navigation-drawer app fixed v-model="drawer" v-if="$store.state.isLoggedIn" :mini-variant.sync="drawerMini" color="beton-grey" src="@/assets/sidebar-bg.jpg">
      <v-list-item class="px-2 py-1" dense>
        <v-list-item-icon style="cursor:pointer" class="mx-2">
          <v-icon size="lg">mdi-apps</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $store.state.companyName }}</v-list-item-title>

        <v-btn icon @click.stop="drawerMiniControl()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <component :is="navmenu"></component>

      <template v-slot:append>
        <div class="pa-2" v-if="!drawerMini">
          <v-btn block text elevation="0" :to="{ 'name': 'Account' }">Account</v-btn>
        </div>
        <div class="pa-2" v-if="!drawerMini">
          <v-btn block color="error" @click="signOut()">Logout</v-btn>
        </div>

        <div class="pa-2" v-if="drawerMini">
          <v-btn block color="error" :to="{ 'name': 'Account' }">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </div>
        <div class="pa-2" v-if="drawerMini">
          <v-btn block color="error" @click="signOut()" class="px-0">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app dense :elevation="appbar_elevation" :color="appbar_color" :src="appbar_img">
      <v-app-bar-nav-icon @click.stop="drawerControl()"></v-app-bar-nav-icon>
      <!-- <v-toolbar-title>PT. BPW</v-toolbar-title> -->

      <!-- <v-menu offset-y min-width="80%" max-height="600">
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" text>
            Activity
          </v-btn>
        </template>

        <v-card text outlined elevation="4">
          <v-card-text style="max-height:600px;overflow-y:auto">
            <v-row>
              <v-col cols="12" md="4" lg="4">
                <v-list two-line class="py-0">
                  <v-list-item link class="py-0">
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="py-0">Satu</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-list>
                  <v-list-item link>
                    <v-list-item-title>Satu</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-list>
                  <v-list-item link>
                    <v-list-item-title>Satu</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <slot name="activity"></slot>
          </v-card-text>
        </v-card>
      </v-menu> -->
      <v-spacer></v-spacer>

      <notification-menu></notification-menu>

      <!-- <v-menu offset-y right bottom left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" icon fab small class="mx-1">
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>

        <v-card min-width="250">
          <v-card-text class="px-0 py-0">
            <v-card-text class="text-center">
              <p>Sorry, not available now!</p>
            </v-card-text>
          </v-card-text>
        </v-card>

      </v-menu> -->

      <account-menu></account-menu>

      <!-- <v-btn icon class="mx-2" @click="dialogMenu = !dialogMenu">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <router-link :to="{ 'name': 'Account' }">
        <v-avatar style="cursor:pointer" size="36">
          <v-img :src="resourceUrl($store.state.loginInfo.profileImage)"></v-img>
        </v-avatar>
      </router-link> -->
    </v-app-bar>

    <v-dialog v-model="dialogMenu" max-width="600">
      <v-card>
        <v-toolbar text elevation="0">
          <v-toolbar-title>Menu</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field solo outlined autofocus text clearable single-line hide-details rounded dense placeholder="Search"></v-text-field>
          <v-btn icon @click.stop="dialogMenu = !dialogMenu">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-4">
          <!-- <v-btn x-large height="72" width="72" color="blue" outlined>
            <div class="d-flex flex-column">
              <v-icon style="font-size:36px">mdi-apps</v-icon>
              <span style="font-size:10px">User</span>
            </div>
          </v-btn> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="dialogMenu = !dialogMenu">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main id="main" class="white main-bg">
      <div>
        <!-- <v-breadcrumbs class="ml-n2 py-1" :items="breadcrumbsData" v-if="breadcrumbsData.length >= 1"></v-breadcrumbs> -->
        <slot></slot>
      </div>
      <!-- <v-btn
        v-if="!drawer && $vuetify.breakpoint.name!='xs'"
        fab
        @click.stop="drawerControl()"
        fixed
        bottom
        class="ml-2"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn
        v-if="drawer && $vuetify.breakpoint.name!='xs'"
        fab
        @click.stop="drawerControl()"
        fixed
        bottom
        class="ml-2"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>-->
    </v-main>

    <v-footer color="white">
      <v-row>
        <v-col class="text-center text-body-2" cols="12">
          &#169; {{ new Date().getFullYear() }} —
          <strong>{{ $store.state.siteCopyright }}</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import NavbarDefault from "@/layouts/inc/NavbarDefault.vue";
import NavbarSa from "@/layouts/inc/NavbarSa.vue";
import NavbarScm from "@/layouts/inc/NavbarScm.vue";
import NotificationMenu from '@/layouts/inc/NotificationMenu.vue';
import AccountMenu from '@/layouts/inc/AccountMenu.vue';
// import mainbg from "@/assets/images/main-bg.png"

export default {
  name: "Default",
  components: {
    NavbarDefault,
    NavbarSa,
    NavbarScm,
    NotificationMenu,
    AccountMenu,
  },
  data() {
    return {
      dialogMenu: null,
      drawer: null,
      drawer2: null,
      drawerMini: null,
      appbar_color: 'transparent',
      appbar_img: '',
      appbar_elevation: 0,
    };
  },
  computed: {
    navmenu() {
      // Handles initial route
      // if (this.$store.state.loginInfo.userLevel === null)
      //   return "NavbarDefault";

      // if (this.$store.state.loginInfo.userLevel === "sa") return "NavbarSa";
      // if (this.$store.state.loginInfo.userLevel === "scm") return "NavbarScm";
      // console.log(this.$store.state.loginInfo.userLevel);
      return "NavbarDefault";
    },
    overlay() {
      return this.$store.state.loadingOverlay;
    },
    breadcrumbsData() {
      return (typeof this.$route.meta.breadcrumbs == 'undefined') ? [] : this.$route.meta.breadcrumbs;
    },
  },
  watch: {},
  methods: {
    drawerControl() {
      if (this.isMobile) {
        this.drawer = !this.drawer;
        this.drawerMini = false;
      } else {
        this.drawer = true;
        this.drawerMini = !this.drawerMini;
      }

      // console.log(this.drawer);
      // console.log(this.drawerMini);
    },
    drawerMiniControl() {
      if (this.isMobile) {
        this.drawer = !this.drawer;
        this.drawerMini = false;
      } else {
        this.drawer = true;
        this.drawerMini = !this.drawerMini;
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleScroll(event) {
      // Any code to be executed when the window is scrolled

      if (window.scrollY > 0) {
        this.appbar_color = 'beton-grey';
        this.appbar_img = require('@/assets/topbar-bg.jpg');
        this.appbar_elevation = 4;
      } else {
        this.appbar_color = 'transparent';
        this.appbar_img = '';
        this.appbar_elevation = 0;
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>